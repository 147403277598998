const colourSchemes = {
  terminal: {
    textAreaBg: 'black',
    lineNumber: 'rgba(0,0,0,0.1)',
    lineNumberChar: 'steelblue',
    btnsBackgroundColor: 'linear-gradient(#e5e5e5, #cecece)',
    tokens: {
      text: '#DEDEDE',
      cursor: '#9F9F9F',
      selBrWord: '#424242',
      activeLine: 'rgba(255,255,255,0.1)',
      keywords: 'tomato',
      constants: '#AE81FF',
      function: '#66D9EF',
      types: '#66D9EF',
      color: 'black',
      operator: 'deeppink',
      parameter: '#E78C45',
      number: '#E78C45',
      identifier: '#5a32a3',
    },
  },
  monokai: {
    textAreaBg: '#272822',
    lineNumber: 'rgba(255,255,255,0.1)',
    lineNumberChar: '#75715E',
    btnsBackgroundColor: 'linear-gradient(#e5e5e5, #cecece)',
    tokens: {
      text: '#F8F8F2',
      cursor: '#9F9F9F',
      selBrWord: '#66D9EF',
      activeLine: 'rgba(255,255,255,0.1)',
      keywords: '#F92672',
      constants: '#F92672',
      function: '#A6E22E',
      types: '#A6E22E',
      variable: '#E69F66',
      string: '#E6DB74',
      comment: '#75715E',
      bracket: 'rgba(255,255,255,0.2)',
      operator: '#F92672',
      parameter: '#66D9EF',
      number: '#AE81FF',
      identifier: '#5a32a3',
    },
  },
  'github-dark': {
    textAreaBg: '#1f2428',
    lineNumber: 'rgba(255,255,255,0.1)',
    lineNumberChar: '#e1e4e8',
    btnsBackgroundColor: '#373e4a',
    color: 'white',
    tokens: {
      text: '#F8F8F2',
      cursor: '#9F9F9F',
      selBrWord: 'white',
      activeLine: 'rgba(255,255,255,0.1)',
      keywords: '#cb2431',
      constants: '#005cc5',
      function: '#8a63d2',
      types: '#22863a',
      variable: '#005cc5',
      string: '#9ecbff',
      comment: '#d1d5da',
      bracket: 'rgba(255,255,255,0.2)',
      operator: '#F92672',
      parameter: '#66D9EF',
      number: '#2188ff',
      identifier: '#e36209',
    },
  },
  OneDark: {
    textAreaBg: '#23272e',
    lineNumber: 'rgba(255,255,255,0.1)',
    lineNumberChar: '#e1e4e8',
    btnsBackgroundColor: '#23272e',
    color: 'white',
    tokens: {
      text: '#F8F8F2',
      cursor: '#9F9F9F',
      selBrWord: 'white',
      activeLine: 'rgba(255,255,255,0.1)',
      keywords: '#c678dd',
      constants: '#d19a66',
      function: '#61afef',
      types: '#e5c07b',
      variable: '#e06c75',
      string: '#98c379',
      comment: '#7f848e',
      bracket: 'rgba(255,255,255,0.2)',
      operator: '#56b6c2',
      parameter: '#66D9EF',
      number: '#e5c07b',
      identifier: '#e36209',
    },
  },
};

export default colourSchemes;
